import { api } from "../../../services/api";

export async function List(page, limit, search) {
  try {
    let response = await api.get(
      `admin/user/collection?limit=${limit}&index=${page}&search=${search}`
    );

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
}

export async function Show(id) {
  try {
    let response = await api.get("admin/user", {
      headers: {
        "user-id": id,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
}

export async function StudioUpdatePlan(email, st_current_signature_type) {
  try {
    let response = await api.put(
      `admin/studio/signature-type-by-email?email=${email}`,
      {
        st_current_signature_type: st_current_signature_type,
      }
    );

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
}

export async function PlaygizUpdatePlan(email, pl_signature_active) {
  try {
    let response = await api.put(
      `admin/playgiz/signature-type-by-email?email=${email}`,
      {
        pl_signature_active: pl_signature_active,
      }
    );

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
}

// export async function ShowGraduationDegree(id) {
//   try {
//     let response = await api.get("candidato-posgraduacao", {
//       headers: {
//         id,
//       },
//     });

//     const data = response.data;

//     return data;
//   } catch (error) {
//     return error;
//   }
// }

// export async function ShowCourse(id) {
//   try {
//     let response = await api.get("candidato-curso", {
//       headers: {
//         id,
//       },
//     });

//     const data = response.data;

//     return data;
//   } catch (error) {
//     return error;
//   }
// }

// export async function VerifyAccount(checked, id) {
//   try {
//     let response = await api.put(`/candidato/aprovado/${id}/`, { checked });

//     const data = response.data;

//     return data;
//   } catch (error) {
//     return error;
//   }
// }
